<template>
    <div class="page1">
        <EForm :formColumns="formColumns"
               :rowSize="4"
               :optionsBtn="true"
               :actionBtn="false"
               :formData="searchForm"
               ref="form"
               @onSearch="onSearch"
               :searchFlag="true"
               @getChange="changeSelect"
               :exportShow="false"
              >
        </EForm>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"

        >
        </ETable>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'Login',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '合同编号',
            prop: 'contractCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '面积',
            prop: 'areas'
          },
          {
            label: '商户类型',
            prop: 'tenantTypeCn'
          },
          {
            label: '主营商品类型',
            prop: 'mainWareTypeName'
          },
          {
            label: '负责人',
            prop: 'signPerson'
          },
          {
            label: '身份证号',
            prop: 'idCard'
          },
          {
            label: '联系电话',
            prop: 'tenantPhone'
          },
          {
            label: '统一社会信用代码',
            prop: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            prop: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            prop: 'bankAccount',
          },
          {
            label: '合同签订人',
            prop: 'createUserCn'
          },
          {
            label: '合同签订时间',
            prop: 'createTime'
          },
          {
            label: '合同生效日期',
            prop: 'inDate'
          },
          {
            label: '合同到期日期',
            prop: 'expireDate'
          },
          {
            label: '续签时间',
            prop: 'createTime',
          },
        ],
        formColumns: [
          {
            title: '摊位号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '负责人 ',
            type: 'text',
            property: 'signPerson',
            show: true
          },
          {
            title: '合同编号',
            type: 'text',
            property: 'contractCode',
            show: true
          },
          {
            title: '合同签订开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '商户类型',
            type: 'select',
            property: 'tenantType',
            show: true,
            options: [
              {
                label: '全部',
                value: '',
              },
              {
                label: '个体',
                value: 1,
              },
              {
                label: '公司',
                value: 2,
              },
              {
                label: '其他',
                value: 3,
              }
            ]
          },
          {
            title: '主营商品类型',
            type: 'text',
            property: 'mainWareTypeName',
            show: true
          },
          {
            title: '合同签订截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '身份证号 ',
            type: 'text',
            property: 'idCard',
            show: true
          },
          {
            title: '合同签订人',
            type: 'text',
            property: 'createUser',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          signPerson: null,
          contractCode: null,
          createTimeStart: null,
          areaId: null,
          tenantType: null,
          status: null,
          createTimeEnd: null,
          placeId: null,
          idCard: null,
          createUser: null,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', "blur", "摊位编号不能为空"),
          areas: vxRule(true, '', "blur", "面积不能为空"),
          areaId: vxRule(true, '', "change", "区域不能为空"),
          boothUseId: vxRule(true, '', "change", "摊位用途不能为空"),
          placeId: vxRule(true, '', "change", "位置不能为空"),
          boothTypeId: vxRule(true, '', "change", "摊位类型不能为空"),
          remark: vxRule(true, '', "blur", "说明不能为空"),
        },
        dialogForm: {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        boothUseOptions: [],
        boothTypeOptions: [],
      }
    },
    watch: {},
    created() {
      this.getData()
      this.getAreaLabel()
    },
    components: {ETable, EButton, EForm},
    beforeMount() {

    },
    methods: {
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      changeSelect(prop){
        if(prop=='areaId'){
          this.getPlaceLabel()
        }
      },
      saveData(boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.boothInfoSubmit(boothInformationId)
          } else {
            return false;
          }
        })
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      async getData() {
        let res = await Http.cmcontractrenewallog(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = res.data.total
        }
      },
      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId:this.searchForm.areaId,
          status:null
        })
        if (res.code == 200) {
          this.formColumns[9].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status:null
        })
        if (res.code == 200) {
          this.formColumns[4].options = res.data
          this.areaOptions = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
